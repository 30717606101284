import React, { Component } from 'react';
import autoin from '../assets/images/logo.svg';


import {
    Container,
    Row,
    Col
} from 'reactstrap';

import moment from 'moment';


function formatPrice(price, dec = 2) {
    return parseFloat(price).toFixed(dec)
}


export default class Order extends React.Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.state = {

        };

    }


    componentDidMount() {
        //this.props.handleLoader(true);
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }




    }


    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
    }


    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on("adminFetchOne", (data) => {
            console.log(data);
            this.setState(data, () => {
                console.log(this.state);
                let products = this.state.products ? this.state.products : [];

                let volume = 0;
                let weight = 0;
                for (let i = 0; i < products.length; i++) {
                    if (products[i].QuantityInSet && products[i].cartQuantity && products[i].Weight && products[i].Volume) {
                        volume += (products[i].cartQuantity / products[i].QuantityInSet) * products[i].Volume.replace(',', '.');
                        weight += (products[i].cartQuantity / products[i].QuantityInSet) * products[i].Weight.replace(',', '.');

                    }
                }

                if (this.state.DeliveryMethod && this.state.DeliveryMethod.maxVolume && this.state.DeliveryMethod.maxWeight) {
                    if (volume > this.state.DeliveryMethod.maxVolume || weight > this.state.DeliveryMethod.maxWeight) {
                        if (volume > this.state.DeliveryMethod.maxVolume && weight < this.state.DeliveryMethod.maxWeight) {
                            let DeliveryMethod = this.state.DeliveryMethod;
                            DeliveryMethod.price = DeliveryMethod.price * (volume / this.state.DeliveryMethod.maxVolume);
                            this.setState({
                                DeliveryMethod: DeliveryMethod
                            })
                        } else if (volume < this.state.DeliveryMethod.maxVolume && weight > this.state.DeliveryMethod.maxWeight) {
                            let DeliveryMethod = this.state.DeliveryMethod;
                            DeliveryMethod.price = DeliveryMethod.price * (weight / this.state.DeliveryMethod.maxWeight);
                            this.setState({
                                DeliveryMethod: DeliveryMethod
                            })
                        } else {

                            let val1 = volume / this.state.DeliveryMethod.maxVolume;
                            let val2 = weight / this.state.DeliveryMethod.maxWeight;

                            if (val1 > val2) {
                                let DeliveryMethod = this.state.DeliveryMethod;

                                DeliveryMethod.price = DeliveryMethod.price * (volume / this.state.DeliveryMethod.maxVolume);
                                this.setState({
                                    DeliveryMethod: DeliveryMethod
                                })

                            } else {
                                let DeliveryMethod = this.state.DeliveryMethod;

                                DeliveryMethod.price = DeliveryMethod.price * (weight / this.state.DeliveryMethod.maxWeight);
                                this.setState({
                                    DeliveryMethod: DeliveryMethod
                                })

                            }
                        }
                    }
                }
            })
        });

        this.props.socketIOClient.emit("adminFetchOne", { query: { _id: this.props[0].match.params.id }, collection: 'orders' });

    }


    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("adminFetch");
        this.props.socketIOClient.removeAllListeners("adminFetchOne");

    }



    render() {
        console.log(this.state);
        let total = this.state.total;

        let parts = [0, 0, 0, 0];


        let products = this.state.products ? this.state.products : [];

        let volume = 0;
        let weight = 0;
        for (let i = 0; i < products.length; i++) {
            if (products[i].QuantityInSet && products[i].cartQuantity && products[i].Weight && products[i].Volume) {
                volume += (products[i].cartQuantity / products[i].QuantityInSet) * products[i].Volume.replace(',', '.');
                weight += (products[i].cartQuantity / products[i].QuantityInSet) * products[i].Weight.replace(',', '.');

            }
        }





        return (
            <div className="order-account">
                <Container>

                    <div className="left">


                    </div>
                    <div className="right">
                        <img src={autoin} className="img-fluid logo" />
                    </div>
                    <div className="top-container">
                        <div className="komitet" style={{ marginTop: 60 }}>
                            <h3></h3>
                            <div className="box">
                                <h6>ИНВЕНТИВЕ ДООЕЛ СКОПЈЕ</h6>
                                <h6>НИКОЛА ВАПЦАРОВ бр. 2-3, СКОПЈЕ - ЦЕНТАР</h6>
                                <h6>074 248 024</h6>

                            </div>

                        </div>

                        <div className="komitet isporuka">
                            <h3>Место на испорака:</h3>
                            {this.state.ShippingAddress ? <div className="box">
                                <h6>{this.state.ShippingAddress.Name} </h6>
                                <h6>{this.state.ShippingAddress.Address ? this.state.ShippingAddress.Address.street : this.state.ShippingAddress.Street} {this.state.ShippingAddress.HouseNumber} {this.state.ShippingAddress.Apartment}</h6>
                                <h6>{this.state.ShippingAddress.Address ? this.state.ShippingAddress.Address.place : this.state.ShippingAddress.City}</h6>
                                <h6>{this.state.ShippingAddress.Phone} </h6>


                            </div>
                                : null}
                            <h4>Страница 1</h4>
                        </div>
                    </div>
                    <div className="naslov">
                        <h1>ФАКТУРА - ИСПОРАКА - {this.state.orderNumber}/{this.state.Charged && this.state.Charged.split('.').pop().slice(-2)}</h1>
                    </div>
                    <div className="heidingTable">
                        <div className="col1">
                            <h3>НАРАЧАНИ </h3>
                            <h3> Датум на промет: {moment.unix(this.state.orderTime).format('DD.MM.YYYY.')} </h3>
                            <h3>Начин на плаќање: {this.state.BillingMethod}</h3>
                            <h3>Место на издавање: СКОПЈЕ</h3>


                        </div>
                        <div className="col2">
                            <h3>ФАКУРИРАНО</h3>
                            <h3> Датум на издавање: {this.state.Charged} </h3>
                        </div>
                        <div className="col4">
                            <h3>ИСПОРАКА</h3>
                            <h3>Начин: {this.state.DeliveryMethod && this.state.DeliveryMethod.name}</h3>

                            <h3> Датум: {this.state.Delivered} </h3>

                            <h3>Валута: {this.state.user && moment.unix(this.state.orderTime + this.state.user.TimeToPay * 60 * 60 * 24).format('DD.MM.YYYY')}</h3>
                        </div>


                    </div>
                    <table className="nasloviTabele">
                        <tr>
                            <td>Рбр</td>
                            <td>Бар код</td>
                            <td>Код и име на ставката</td>
                            <td>Кол.</td>
                            <td>Цена<br /> без ДДВ</td>
                            <td>Износ <br /> без ДДВ</td>
                            <td>P%</td>
                            <td>ДДВ<br/>по единица</td>
                            <td>ДДВ</td>
                            <td>Износ <br /> со ДДВ</td>

                        </tr>

                        {
                            this.state.products ? this.state.products.map((item, idx) => {
                                return (
                                    <tr className="white" key={idx}>

                                        <td >{idx + 1}</td>
                                        <td>{item.BarCode}</td>
                                        <td>{item.Alias} - {item.package && item.package.name}</td>
                                        <td>{item.cartQuantity}</td>
                                        <td>{formatPrice((item.price * 100) / 118)}</td>
                                        <td>{formatPrice(item.cartQuantity * ((item.price * 100) / 118))}</td>
                                        <td>18</td>
                                        <td>{formatPrice(((item.price) - ((item.price * 100) / 118)))}</td>
                                        <td>{formatPrice(((item.price) - (((item.price * 100) / 118))) * item.cartQuantity)}</td>
                                        <td>{formatPrice(item.price * item.cartQuantity)}</td>


                                    </tr>

                                )
                            })

                                : null
                        }

                    </table>
                    <div className="underTableLeft">
                        {/*<h6>UGOVOR BR. {this.state.orderNumber}/{this.state.Charged && this.state.Charged.split('.').pop().slice(-2)} - {this.state.Warehouse ? this.state.Warehouse.split('-')[0].trim() : ''} zaključen dana {this.state.Charged} u Bijeljini između</h6>
                        <h5>doo EXCALIBUR@ kao dobavljač sa jedne strane, i {this.state.BillingAddress && this.state.BillingAddress.Company ? this.state.BillingAddress.Company : this.state.BillingAddress ? this.state.BillingAddress.Name : ''}. kao komitent sa druge strane</h5>
                        <div className="clanovi">
                            <p>Član 1. Ugovorene strane su sporazumno ugovorile cjene artikala koje su prikazane na poreskoj fakturi br. {this.state.orderNumber}/{this.state.Charged && this.state.Charged.split('.').pop().slice(-2)}-{this.state.Warehouse ? this.state.Warehouse.split('-')[0].trim() : ''}</p>
                            <p>Član 2. Ovu narudžbenicu je kupac potvrdio i primio putem e-maila.</p>
                            <p>Član 3. Svojim potpisom i pečatom kupac je saglasan da ova narudžbenica ima snagu kupoprodajnog ugovora.</p>
                            <p>Član 4. Valutni rok je iskazan na narudžbenici, nakon čijeg isteka, dobavljač zadržava pravo zaračunavanje zakonske zatezne kamate an teret komitenta.</p>
                            <p>Član 5. Kupac je obavezan prekontrolisati robu pri preuzimanju. Naknadne reklamacije ne važe.</p>
                            <p>Član 6. U slučaju spora po ovom ugovoru, ugovorne strane prihvataju nadležnostu suda u Bijeljini.</p>
                            <p>Član 7. Uplatiti na Ž.R. 555-001-00003818-58 NOVA BANKA AD - BIJELJINA</p>

                    </div>*/}
                    </div>
                    <div className="underTableRigth">
                        <h3 className="table-box-title">ИСПОРАКА</h3>
                        <div className="leftUnderTable">
                            <h6><span>Основа без ДДВ:</span></h6>
                            <h6><span>ДДВ:</span></h6>
                            <h6><span>Во целост:</span></h6>


                        </div>
                        <div className="rightUnderTable ">
                            <h6><span>{formatPrice(this.state.DeliveryMethod ? (this.state.DeliveryMethod.price * 100 )/118 : 0)}</span></h6>
                            <h6><span>{formatPrice(this.state.DeliveryMethod ? this.state.DeliveryMethod.price -  (this.state.DeliveryMethod.price * 100 )/118 : 0)}</span></h6>
                            <h6><span>{formatPrice(this.state.DeliveryMethod ? this.state.DeliveryMethod.price : 0)}</span></h6>

                        </div>

                        <h3 className="table-box-title">UKUPNO</h3>

                        <div className="leftUnderTable">
                            <h6><span>Износ без ДДВ:</span></h6>
                            <h6>Износ на ДДВ:</h6>
                            <h6><span>Износ со ДДВ:</span></h6>
                        </div>
                        <div className="rightUnderTable">
                            <h6><span>{formatPrice(((this.state.subtotal) * 100) / 118 + (this.state.DeliveryMethod ? (this.state.DeliveryMethod.price * 100 )/118 : 0)  ) }</span></h6>
                            <h6>{formatPrice( ((this.state.subtotal) - (((this.state.subtotal) * 100) / 118) + (this.state.DeliveryMethod ? (this.state.DeliveryMethod.price -  (this.state.DeliveryMethod.price * 100 )/118) : 0)))}</h6>
                            <h6>{formatPrice(this.state.total)}</h6>

                        </div>


                        <div className="leftUnderTable ">

                            <h6><span>ЗА ПЛАЌАЊЕ:</span></h6>
                        </div>
                        <div className="rightUnderTable ">
                            <h6><span>{formatPrice(this.state.total)}</span></h6>
                        </div>





                    </div>
                    <div className="note">
                        <p>Забелешка: <span>{this.state.ShippingAddress && this.state.ShippingAddress.Node}</span></p>
                    </div>
                    <div className="potpis">
                        <div className="col1">
                            <h6>Фактурирана:</h6>
                            <hr></hr>
                        </div>
                        <div className="col2">
                            <div className="cjena">

                                {/*<h5>SLOVIMA: dvestotinesedamdesetsedam i 75/100</h5>*/}
                            </div>
                            <h6>Тој ја предал робата:</h6>
                            <hr></hr>

                        </div>
                        <div className="col3">

                            <h6>Тој ја презел робата:</h6>
                            <hr></hr>

                        </div>
                    </div>
                </Container>


            </div>
        )
        /*
        return (
            <div className="order-account">
                <div className="logo">
                    <img src={logo} className="l"/>
                </div>
                <div className="content">
           
                    <h1>NARUDŽBENICA</h1> 
                    <img src={autoin} className="autoinLogo"/>
                
                    
                    <p>doo.EXCALIBUR@, Dušana Branjanina 37., 76300 Bijeljina, Republika Srpska, Bosna i Hercegovina<br></br>
                        www.autoin.ba tel: +387-55-202-297 E-mail: autoin@teol.net
                    </p>
                
                </div>
                <table className="buyer">

                    <tbody>
                        <tr>
                            <th width="5%">Kupac: </th>
                            <td style={{borderRight: 0, paddingLeft: 10}}>
                            {this.state.BillingAddress ? `${this.state.BillingAddress.FirstName} ${this.state.BillingAddress.LastName} ` : null}
                            {this.state.BillingAddress ? `${this.state.BillingAddress.Street}, ${this.state.BillingAddress.City} ${this.state.BillingAddress.Zipcode} ` : null}
                            <br/>
                            {this.state.BillingAddress ? ` ${this.state.BillingAddress.Company}`  : ''}</td>
                            <td align="right">STATUS: &nbsp; </td>
                            <td width="20%" align="center">{this.state.Status}</td>

                        </tr>



                    </tbody>


                </table>

                <table className="data">
                    <tbody>
                        <tr>
                            <td className="addres" colSpan="2">Adresa Kupca: {this.state.ShippingAddress ? `${this.state.ShippingAddress.FirstName} ${this.state.ShippingAddress.LastName}, ${this.state.ShippingAddress.Street}, ${this.state.ShippingAddress.City} ${this.state.ShippingAddress.Zipcode}`  : ''} </td>
                        </tr>
                        <tr>
                            <td className="addres" colSpan="2">Telefon Kupca: {this.state.ShippingAddress ? this.state.ShippingAddress.Phone  : ''} </td>
                        </tr>

                        <tr className="dataPayment">

                            <td width="50%">Datum naručivanja: {moment.unix(this.state.orderTime).format('DD.MM.YYYY HH:mm')}</td>
                            <td width="50%">Plaćanje: {this.state.BillingMethod === 'placanje-prilikom-pouzeca' ? 'Plaćanje prilikom pouzeća' : 'Uplata na tekući račun'}</td>
                        </tr>

                        <tr className="dateDelivery">
                            <td width="50%">Datum isporuke: {this.state.Delivered}</td>
                            <td width="50%">Način Isporuke: {this.state.DeliveryMethod ?  this.state.DeliveryMethod.name :  ''} </td>

                        </tr>

                        <tr className="packaging">
                            <td width="50%" style={{borderRight: '2px solid black'}}>Pakovao:</td>
                            <td className="left" style={{borderRight: '2px solid black'}}>
                                Odvojeno: 
                            <td className="right"> Da - Ne</td>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" className="articlesData">
                    <tbody align="center">
                    <tr>
                            <th width="5%"> №</th>
                            <td width="10%">Šifra</td>
                            <td width="27%">Artikal</td>
                            <td width="8%" >J.mere</td>
                            <td width="30%">Količina</td>
                            <td width="20%">Napomena</td>
                        </tr>

                        {
                            this.state.products ? this.state.products.map((item, idx) => {
                                return (
                                    <tr key={idx}>
                                    <th >{idx+1}</th>
                                    <td>{item.Alias}</td>
                                    <td>{item.Name}</td>
                                    <td> {item.OrderUnit}</td>
                                    <td>{item.cartQuantity}</td>
                                    <td></td>
        
                                </tr>
                
                                )
                            })

                            : null
                        }
                      
                     
                  
                    

                    </tbody>
                </table>

                <table className="signature">
                    <tbody>

                        <tr>
                            <td width="50%" align="center">Potpis Komercijale doo. EXCALIBUR@</td>
                            <td width="50%" align="center">Potpis Kupca/Naručioca</td>

                        </tr>
                        <tr>
                            <td width="50%" height="30px"></td>
                            <td width="50%" height="30px"></td>

                        </tr>
                    </tbody>
                </table>
            </div>
        )*/
    }

}

