
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import {
    Col,
    Row,
    Container
} from 'reactstrap';
import Select from './selectWithSearch';
import NormalSelect from './select';
import Check from './check2';

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        console.log(this.props.products);
        let products

        let value = this.props.value ? this.props.value : [];

        return (
            <div className="order-view">
                <table>
                    <tr>
                        <th>Artikal</th>
                        <th>Pakovanje</th>
                        <th>Cijena</th>
                        <th>Kolicina</th>
                        <th>Iznos</th>
                        <th>Gratis</th>
                        <th>Akcija</th>
                    </tr>

                    {
                        [...value, {}].map((row, ridx) => {
                            return (
                                <tr>

                                    <td>
                                        <Select onChange={(id) => {
                                            let changeValue = value;
                                            // if (ridx == value.length - 1) {
                                            //     changeValue.push({});
                                            // }
                                            if (!changeValue[ridx]) {
                                                changeValue[ridx] = {}
                                            }

                                            changeValue[ridx]._id = id;
                                            changeValue[ridx].Name = this.props.products.filter(item => item._id == id)[0].Name;
                                            changeValue[ridx].Alias = this.props.products.filter(item => item._id == id)[0].Alias;

                                            changeValue[ridx].package = null;
                                            changeValue[ridx].cartQuantity = 1;

                                            this.props.onChange(changeValue);
                                            this.forceUpdate()

                                        }} value={row._id}>
                                            {this.props.products.map((item, idx) => {
                                                return <option search={item.Alias + ' - ' + item.Name} value={item._id}>{item.Alias + ' - ' + item.Name}</option>
                                            })}
                                        </Select>
                                    </td>
                                    <td>
                                        {row._id ?
                                            <NormalSelect onChange={(id) => {
                                                let changeValue = value;
                                                changeValue[ridx].package = JSON.parse(id);
                                                changeValue[ridx].package.price = (changeValue[ridx].package.mpc * 1.2).toFixed(2)
                                                changeValue[ridx].price = (changeValue[ridx].package.mpc * 1.2).toFixed(2)
                                                changeValue[ridx].__price = (changeValue[ridx].package.mpc * 1.2).toFixed(2)

                                                this.props.onChange(changeValue);
                                                this.forceUpdate()

                                            }} value={JSON.stringify(
                                                Object.values(this.props.products.filter(item => item._id == row._id)[0].package).filter((item) => row.package && item.name == row.package.name)[0])}>
                                                {Object.values(this.props.products.filter(item => item._id == row._id)[0].package).filter((item) => item.name).map((item, idx) => {
                                                    return <option value={JSON.stringify(item)}>{item.name}</option>
                                                })}
                                            </NormalSelect>
                                            :
                                            null
                                        }


                                    </td>
                                    <td>{row.package ? row.package.price : null}</td>
                                    <td>
                                        {row.package ?
                                            <input value={row.cartQuantity} onChange={(e) => {
                                                let changeValue = value;
                                                changeValue[ridx].cartQuantity = parseInt(e.target.value)
                                                this.props.onChange(changeValue);
                                                this.forceUpdate()

                                            }}></input>
                                            :
                                            null
                                        }
                                    </td>
                                    <td>{row.package ? row.package.price * row.cartQuantity : null}</td>
                                    <td><Check translate={this.props.translate} value={row.price == 1} onChange={(v) => {

                                        if (v) {
                                            let changeValue = value;
                                            changeValue[ridx].cartQuantity = 1;
                                            changeValue[ridx].price = 1;
                                            changeValue[ridx].package.price = 1;
                                            this.props.onChange(changeValue);
                                            this.forceUpdate()
    
                                        }else{
                                            let changeValue = value;
                                            changeValue[ridx].cartQuantity = 1;
                                            changeValue[ridx].price = changeValue[ridx].__price;
                                            changeValue[ridx].package.price = changeValue[ridx].__price;

                                            this.props.onChange(changeValue);
                                            this.forceUpdate()
    
                                        }

                                    }}></Check></td>

                                    <td>
                                        {row._id ?
                                            <button type="button" onClick={() => {
                                                let changeValue = value;
                                                value.splice(ridx, 1);
                                                this.props.onChange(changeValue);
                                                this.forceUpdate()

                                            }} className="button" ><i className="mdi mdi-delete"></i> </button>


                                            :
                                            null}
                                    </td>

                                </tr>

                            )
                        })
                    }
                </table>
            </div>
        );
    }
}

export default List;